import {Component, effect, inject, Input, OnInit, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Router, RouterLink} from '@angular/router';
import {NotificationService} from "../../../../core";
import {DotLoadingServices} from "../../../../core/services/dot-loading.Services";
import {VisitorService} from "../../services/visitor.service";
import {CategoryDTO, VisitorDTO, VisitorSearchResultDTO} from "../../interfaces/visitor.entity";
import {DatePipe, NgClass} from "@angular/common";
import {take} from "rxjs/operators";
import {iif} from "rxjs";
import {Employee} from "../../../shared/interface/employee.entity";
import {EmployeeService} from "../../../shared/services/employee.service";
type FieldType = 'requestBy' | 'contactPerson';

@Component({
  selector: 'app-visitor-create',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    DatePipe,
    RouterLink,

  ],
  templateUrl: './visitor-create.component.html',
  styleUrl: './visitor-create.component.scss'
})
export class VisitorCreateComponent implements OnInit {

  private notification = inject(NotificationService);
  private loading = inject(DotLoadingServices);
  private visitorService = inject(VisitorService);
  private employeeService = inject(EmployeeService);
  private router = inject(Router);


  $$selectedVisitorId = signal(0);

  employeeDTO: Employee[] = []
  categoryDTO: CategoryDTO[] = [];

  @Input() hideInGrid: boolean = true;
  @Input() isFormDisabled: boolean = false;
  @Input() gridCols: string = 'xl:grid-cols-6';

  readonly BOI_CATEGORY_ID = '3';
  minDate: string = new Date().toISOString().split('T')[0];
  minTime: string = '';
  isEmailValid = true;

  filteredEmployees: Record<FieldType, any[]> = {
    requestBy: [],
    contactPerson: []
  };
  searchTerms: Record<FieldType, string> = {
    requestBy: '',
    contactPerson: ''
  };
  isListVisible: Record<FieldType, boolean> = {
    requestBy: false,
    contactPerson: false
  };

  visitor: VisitorDTO = {
    category: "", company: "", contactNo: "", contactPerson: "", email: "",
    meetingLocation: "", name: "", nicNo: "", plant: "", purpose: "", reqBy: "",
    reqDate: "", reqTime: "", status: "", title: "", type: "", boi: ""
  }


  constructor() {
    this.loadCategories();

    effect(() => {
      const visitor = this.visitorService.active()
      if (visitor) {
        this.$$selectedVisitorId.set(visitor.id || 0);
        this.visitor = {
          ...visitor,
          reqDate: visitor.reqDate ? visitor.reqDate.split('T')[0] : ''
        };
        console.log(visitor.status);
        this.searchTerms['requestBy'] = visitor.reqBy || '';
        this.searchTerms['contactPerson'] = visitor.contactPerson || '';
        this.filterEmployees('requestBy');
        this.filterEmployees('contactPerson');
      }
    }, { allowSignalWrites: true });
    effect(() => {
      this.employeeDTO = this.employeeService.all()
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    this.updateMinTime();

  }

  Create() {
    this.loading.setLoading(true);
    const visitorDTO: VisitorDTO = {
      ...this.visitor,
      reqDate: this.visitor.reqDate || new Date().toISOString().split('T')[0],
      status: 'Pending'
    };

    const UpdateDTO = {
      type: this.visitor.type, company: this.visitor.company, title: this.visitor.title,
      name: this.visitor.name, category: this.visitor.category, plant: this.visitor.plant,
      meetingLocation: this.visitor.meetingLocation, boi: this.visitor.boi,
      reqDate: this.visitor.reqDate, reqTime: this.visitor.reqTime, nicNo: this.visitor.nicNo,
      email: this.visitor.email, contactNo: this.visitor.contactNo, purpose: this.visitor.purpose,
      reqBy: this.visitor.reqBy, contactPerson: this.visitor.contactPerson, updatedBy: 'Dev'
    };

    iif(
      () => this.$$selectedVisitorId() > 0,
      this.visitorService.update(this.$$selectedVisitorId(), UpdateDTO),
      this.visitorService.create(visitorDTO)
    ).pipe(take(1)).subscribe({
      next: () => {
        this.loading.setLoading(false);
        const action = this.$$selectedVisitorId() > 0 ? 'updated' : 'created';
        this.notification.showNotification({
          type: 'success',
          message: `Appointment for ${this.visitor.name} has been ${action} successfully.`,
        });
        this.router.navigate(['/visitors'], { queryParams: { status: 'pending' } }).then(() =>
          this.resetForm()
        );
      },
      error: (err) => {
        this.loading.setLoading(false);
        const action = this.$$selectedVisitorId() > 0 ? 'update' : 'create';
        this.notification.showNotification({
          type: 'error',
          message: `We couldn't ${action} the appointment for ${this.visitor.name}.`,
        });
        console.error(err);
      }
    });
  }


  private resetForm() {
    this.visitorService.initial()
  }

  get gridClass(): string {
    return this.gridCols === 'xl:grid-cols-2' ? 'xl:grid-cols-2' : 'xl:grid-cols-3';
  }

  loadCategories() {
    this.visitorService.getCategories().subscribe(
      () => {
        this.categoryDTO = this.visitorService.categoryAll();
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select();
  }

  filterEmployees(field: FieldType) {
    const searchTerm = this.searchTerms[field].toLowerCase();
    this.filteredEmployees[field] = this.employeeDTO.filter(employee =>
      employee.code.toLowerCase().includes(searchTerm) ||
      employee.displayName.toLowerCase().includes(searchTerm)
    );
    this.isListVisible[field] = this.filteredEmployees[field].length > 0;

    const exactMatch = this.filteredEmployees[field].find(
      employee => employee.displayName.toLowerCase() === searchTerm
    );
    if (exactMatch) {
      this.selectEmployee(exactMatch, field);
    }
  }

  selectEmployee(employee: Employee, field: FieldType) {
    if (field === 'requestBy') {
      this.visitor.reqBy = employee.displayName;
    } else {
      this.visitor.contactPerson = employee.displayName;
    }
    this.searchTerms[field] = employee.displayName;
    this.isListVisible[field] = false;
  }

  updateMinTime() {
    if (this.visitor.reqDate === this.minDate) {
      const now = new Date();
      now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
      this.minTime = now.toTimeString().split(' ')[0].slice(0, 5);
    } else {
      this.minTime = '09:00';
    }
  }

  onDateChange() {
    this.updateMinTime();
    if (this.visitor.reqDate === this.minDate && this.visitor.reqTime < this.minTime) {
      this.visitor.reqTime = this.minTime;
    }
  }

  isBOICategory(): boolean {
    return this.visitor.category === this.BOI_CATEGORY_ID;
  }

  isFormValid(): boolean {
    const requiredFields = {
      type: this.visitor.type,
      title: this.visitor.title,
      name: this.visitor.name,
      category: this.visitor.category,
      nicNo: this.visitor.nicNo,
      contactNo: this.visitor.contactNo,
      plant: this.visitor.plant,
      reqDate: this.visitor.reqDate,
      reqTime: this.visitor.reqTime,
      purpose: this.visitor.purpose,
      reqBy: this.visitor.reqBy,
      contactPerson: this.visitor.contactPerson
    };

    if (this.visitor.category === this.BOI_CATEGORY_ID && !this.visitor.boi) {
      return false;
    }
    return Object.values(requiredFields).every(value => !!value) &&
      (this.visitor.email === '' || this.isEmailValid);
  }

  numbersOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
    this.visitor.contactNo = input.value;
  }

  validateEmail() {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.isEmailValid = !this.visitor.email || emailRegex.test(this.visitor.email);
  }


}





