<div class="main">
  <h6 class="text-lg font-bold pb-4">Visitor Information</h6>
  <form [ngClass]="['grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-6', gridClass]">
    <div class="relative">
      <select id="type" class="textField" required [(ngModel)]="visitor.type" name="visitorType"
              [disabled]="isFormDisabled">
        <option value="" hidden disabled>Select Type</option>
        <option value="requested">Requested</option>
        <option value="walk_in">Walk-in</option>
      </select>
      <label for="type" class="label">Visitor Type<span class="required">*</span></label>
    </div>

    @if (hideInGrid) {
      <div class="flex space-x-2 ">
        <div class="relative flex-shrink-0 w-2/5">
          <select id="visitorTitle" class="textField" required [(ngModel)]="visitor.title" [disabled]="isFormDisabled" name="visitorTitle">
            <option value="" hidden disabled selected>Title</option>
            <option value="Mr.">Mr</option>
            <option value="Ms.">Ms</option>
            <option value="Mrs.">Mrs</option>
            <option value="Miss.">Miss</option>
            <option value="Hon.">Hon</option>
          </select>
          <label class="label text-sm" for="visitorTitle">Title <span class="required">*</span></label>
        </div>

        <div class="relative flex-grow">
          <input [(ngModel)]="visitor.name" type="text" id="visitorName" name="visitorName" class="textField peer"
                 placeholder=" " required [disabled]="isFormDisabled">
          <label class="label" for="visitorName">Visitor Name <span class="required">*</span></label>
        </div>
      </div>
    }
    <div class="flex space-x-2">
      <div class="relative" [ngClass]="isBOICategory() ? 'w-3/4' : 'w-full'">
        <div class="relative">
          <select id="visitorCategory" class="textField" required [(ngModel)]="visitor.category" name="visitorCategory"
                  [disabled]="isFormDisabled" >
            <option value="" hidden disabled>Categories</option>
            @for (category of categoryDTO; track category.categoryId) {
              <option [value]="category.categoryId">{{ category.categoryName }}</option>
            } @empty {
              <option disabled>No categories available</option>
            }
          </select>
          <label for="visitorCategory" class="label">Visitor Category<span class="required">*</span></label>
        </div>
      </div>

      @if (isBOICategory()) {
        <div class="relative w-1/4">
          <input type="text" id="BOI" class="textField peer" placeholder=" " required
                 [(ngModel)]="visitor.boi" name="visitorBoi" [disabled]="isFormDisabled"
          >
          <label class="label" for="BOI">BOI No <span class="required">*</span></label>
        </div>
      }
    </div>

    <div class="relative">
      <input type="text" id="visitorCompany" class="textField peer" placeholder=" " required
             [(ngModel)]="visitor.company" name="visitorCompany" [disabled]="isFormDisabled">
      <label class="label" for="visitorCompany">Visitor Company</label>
    </div>

    <div class="relative">
      <input type="text" id="visitorNicNo" class="textField peer" placeholder=" " [(ngModel)]="visitor.nicNo"
             name="visitorNicNo" [disabled]="isFormDisabled">
      <label class="label" for="visitorNicNo">NIC | Passport No <span class="required">*</span></label>
    </div>
    <div class="relative">
      <input
        [disabled]="isFormDisabled"
        type="email"
        id="visitorEmail"
        [ngClass]="{'textField peer': isEmailValid || !visitor.email, 'errorField': !isEmailValid && visitor.email}"
        placeholder=" "
        required
        [(ngModel)]="visitor.email"
        (ngModelChange)="validateEmail()"
        name="visitorEmail">
      <label
        [ngClass]="{'label': isEmailValid || !visitor.email, 'errorLbl': !isEmailValid && visitor.email}"
        for="visitorEmail">
        Visitor Email
      </label>
    </div>
    <div class="relative">
      <input type="text"
             [disabled]="isFormDisabled"
             id="visitorContactNo"
             class="textField peer"
             placeholder=" "
             required
             [(ngModel)]="visitor.contactNo"
             (input)="numbersOnly($event)"
             name="visitorContactNo">
      <label class="label" for="visitorContactNo">Visitor Contact No <span class="required">*</span></label>
    </div>

  </form>
</div>


<div class="main">
  <h6 class="text-lg font-bold pb-4">Visit Details</h6>
  <form [ngClass]="['grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6', gridClass]">


      <div class="relative flex-grow">
        <input type="text" id="visitorPlant" class="textField peer" placeholder=" " [(ngModel)]="visitor.plant"
               name="visitorPlant" [disabled]="isFormDisabled">
        <label class="label" for="visitorPlant">Visiting Plant <span class="required">*</span></label>
      </div>
      <div class="relative flex-grow">
        <input type="text" id="visitorMeetingLocation" class="textField peer" placeholder=" "
               [(ngModel)]="visitor.meetingLocation" name="visitorMeetingLocation"
               [disabled]="isFormDisabled">
        <label class="label" for="visitorMeetingLocation">Meeting Location</label>
      </div>


    @if (visitor.status.toLowerCase() !== 'checkedout') {
      <div class="flex gap-2">
        <div class="relative flex-grow">
          <input type="date"
                 [disabled]="isFormDisabled"
                 id="visitorRequestedDate"
                 class="textField peer"
                 placeholder=" "
                 [(ngModel)]="visitor.reqDate"
                 (ngModelChange)="onDateChange()"
                 name="visitorRequestedDate"
                 [min]="minDate">
          <label class="label" for="visitorRequestedDate">Requested Date <span class="required">*</span></label>
        </div>
        <div class="relative flex-shrink-0 w-2/5">
          <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
          <input type="time"
                 [disabled]="isFormDisabled"
                 id="time"
                 class="textField peer"
                 [min]="minTime"
                 max="18:00"
                 required
                 [(ngModel)]="visitor.reqTime"
                 name="visitorRequestedTime"/>
          <label for="time" class="label">Requested Time <span class="required">*</span></label>
        </div>
      </div>
    }
    <div class="relative">
      <input type="text" id="visitorPurpose" class="textField peer" placeholder=" " required
             [(ngModel)]="visitor.purpose" name="visitorPurpose" [disabled]="isFormDisabled">
      <label class="label" for="visitorPurpose">Visitor Purpose <span class="required">*</span></label>
    </div>

    <div class="relative">
      <div class="searchable-dropdown">
        <div class="relative">
          <input (click)="selectAll($event)"
                 type="text"
                 [disabled]="isFormDisabled"
                 id="requestBy"
                 class="floating-btn peer pr-10"
          (focus)="isListVisible['requestBy'] = true"
          (input)="filterEmployees('requestBy')"
          [(ngModel)]="searchTerms['requestBy']"
          placeholder=" "
          [ngModelOptions]="{standalone: true}"
          />
          <div class="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
        </div>
        @if (isListVisible['requestBy']) {
          <ul class="list">
            @for (option of filteredEmployees['requestBy'].slice(0, 5); track option.id) {
              <li class="item" (click)="selectEmployee(option, 'requestBy')">
                {{ option.code }} - {{ option.displayName }}
              </li>
            }
          </ul>
        }
        <label for="requestBy" class="floating-lbl">Requested By <span class="text-red-700">*</span></label>
      </div>
    </div>

    <!-- Contact Person field -->
    <div class="relative">
      <div class="searchable-dropdown">
        <div class="relative">
          <input (click)="selectAll($event)"
                 type="text"
                 [disabled]="isFormDisabled"
                 id="contactPerson"
                 class="floating-btn peer pr-10"
          (focus)="isListVisible['contactPerson'] = true"
          (input)="filterEmployees('contactPerson')"
          [(ngModel)]="searchTerms['contactPerson']"
          placeholder=" "
          [ngModelOptions]="{standalone: true}"
          />
          <div class="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
        </div>
        @if (isListVisible['contactPerson']) {
          <ul class="list">
            @for (option of filteredEmployees['contactPerson'].slice(0, 5); track option.id) {
              <li class="item" (click)="selectEmployee(option, 'contactPerson')">
                {{ option.code }} - {{ option.displayName }}
              </li>
            }
          </ul>
        }
        <label for="contactPerson" class="floating-lbl">Contact Person <span class="text-red-700">*</span></label>
      </div>
    </div>

    @if (!hideInGrid) {
      @if (!['pending','approved', 'rejected', 'canceled'].includes(visitor.status.toLowerCase() || '' )) {
        <div class="relative">
          <input id="checkedIn" class="textField peer" placeholder=" "
                 [disabled]="isFormDisabled"
                 [ngModel]="visitor.checkIn | date:'yyyy-MM-dd - hh:mm a'"
                 name="checkedIn">
          <label class="label" for="checkedIn">Checked-In at </label>
        </div>
        @if (visitor.status.toLowerCase() !== 'checkedin') {
          <div class="relative">
            <input id="checkedOut" class="textField peer" placeholder=" "
                   [disabled]="isFormDisabled"
                   [ngModel]="visitor.checkOut | date:'yyyy-MM-dd - hh:mm a'"
                   name="checkedOut">
            <label class="label" for="checkedOut">Checked-Out at</label>
          </div>
        }
      }
    }

    @if (!hideInGrid) {
      @if (['rejected', 'canceled',].includes(visitor.status.toLowerCase() || '') && (visitor.reason !== '') ) {
        <div class="relative">
          <input id="reason" class="textField peer" placeholder=" " [(ngModel)]="visitor.reason"
                 [disabled]="isFormDisabled"
                 name="reason">
          <label class="label" for="reason">Reason</label>
        </div>
      }
    }

    @if (hideInGrid) {
      <div>
        <div class="relative flex-shrink-0 w-2/4">
          <button type="button"
                  (click)="Create()"
                  [disabled]="!isFormValid()"
                  [ngClass]="{'blue-button': isFormValid(), 'disabled-button': !isFormValid()}">
            {{ $$selectedVisitorId() > 0 ? 'Update Visitor Details' : 'Create Visitor' }}
          </button>

        </div>
      </div>
    }
  </form>
</div>

