<div class="w-full flex items-center p-4 text-gray-500 rounded-2xl shadow-2xl"
     [id]="'toast-' + type"
     [ngClass]="{
       'animate-fade animate-duration-500 animate-ease-linear animate-reverse': isLeaving,
  'animate-fade-up animate-duration-[700ms]': !isLeaving,
       'bg-green-50': type === 'success',
       'bg-red-50': type === 'error',
       'bg-orange-50': type === 'alert',
       'bg-blue-50': type === 'confirm'
     }">
  <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
       [ngClass]="{
         'text-green-500 bg-green-200': type === 'success',
         'text-red-500 bg-red-200': type === 'error',
         'text-orange-500 bg-orange-200': type === 'alert',
         'text-blue-500 bg-blue-200': type === 'confirm'
       }">
    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
         viewBox="0 0 20 20">
      @if (type === 'success') {
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
      } @else if (type === 'error') {
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
      } @else if (type === 'alert') {
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
      } @else if (type === 'confirm') {
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z"/>
      }
    </svg>
    <span class="sr-only">
      {{ type === 'success' ? 'Success' : type === 'error' ? 'Error' : type === 'alert' ? 'Warning' : 'Confirmation' }}
      icon
    </span>
  </div>
  <div class="ms-3 text-sm font-normal pr-4">{{ message }}</div>
  <button type="button"
          (click)="closeNotification()"
          class="ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8"
          [attr.data-dismiss-target]="'#toast-' + type"
          aria-label="Close"
          [ngClass]="{
            'bg-green-200 text-green-500 hover:bg-green-300 hover:text-green-900 focus:ring-green-300': type === 'success',
            'bg-red-200 text-red-500 hover:bg-red-300 hover:text-red-900 focus:ring-red-300': type === 'error',
            'bg-orange-200 text-orange-500 hover:bg-orange-300 hover:text-orange-900 focus:ring-orange-300': type === 'alert',
            'bg-blue-200 text-blue-500 hover:bg-blue-300 hover:text-blue-900 focus:ring-blue-300': type === 'confirm'
          }">
    <span class="sr-only">Close</span>
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
    </svg>
  </button>
</div>
