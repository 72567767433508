<div class="main">
  <div class="overflow-x-auto">

    <div class="flex flex-nowrap items-center space-x-2 overflow-x-auto pb-4 bg-white w-full">
      <!-- Dropdown menu -->
      <div class="flex-shrink-0">
        <select id="type" class="selectField focus:ring-gray-100  focus:border-gray-300  w-full"
                [(ngModel)]="searchParams.type" (keyup.enter)="fetchVisitors()"
                name="visitorType">
          <option selected hidden value="">Type</option>
          <option value="requested">Requested</option>
          <option value="walk_in">Walk-in</option>
          <option value="">All</option>
        </select>
      </div>
      <!-- Search Field -->
      <div class="relative  sm:w-auto ">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input type="search" id="table-search" [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)"
               (keyup.enter)="fetchVisitors()"
               class="focus:ring-gray-100  focus:border-gray-300 p-2.5  block w-full pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
               placeholder="Name or NIC">
      </div>
      <div class="flex-shrink-0">
        <button type="submit" (click)="fetchVisitors()" class="button-blue  inline-flex items-center whitespace-nowrap">
          <svg class="w-4  h-5 sm:mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
          <span class="hidden sm:inline">Search</span>
        </button>
      </div>
    </div>

    <div class="table-wrapper overflow-x-auto">
      <table class="table">
        <thead class="table-thead">
        <tr>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Company</th>
          <th scope="col" class="px-6 py-3">Contact No</th>
          <th scope="col" class="px-6 py-3">NIC | Passport</th>
          <th scope="col" class="px-6 py-3">Contact Person</th>
          <th scope="col" class="px-6 py-3">Purpose</th>
          <th scope="col" class="px-6 py-3">Requested Date</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Action</th>
        </tr>

        </thead>
        <tbody>
          @if (visitorDTO.length) {
            @for (visitor of visitorDTO; track visitor.id) {
              <tr class="bg-white border-b">
                <td class="px-6 py-4">{{ visitor.title }} {{ visitor.name }}</td>
                <td class="px-6 py-4">{{ visitor.company }}</td>
                <td class="px-6 py-4">{{ visitor.contactNo }}</td>
                <td class="px-6 py-4">{{ visitor.nicNo }}</td>
                <td class="px-6 py-4">{{ visitor.contactPerson }}</td>
                <td class="px-6 py-4">{{ visitor.purpose }}</td>
                <td class="px-6 py-4">
                  {{ visitor.reqDate + ' ' + visitor.reqTime | date:'yyyy-MM-dd - h:mm a' }}
                </td>
                <td class="px-6 py-4"><span class="status-badge"
                                            [ngClass]="visitor.status.toLowerCase()">{{ visitor.status }}</span>
                </td>
                <td class="px-6 py-4">
                  <svg width="20" height="20" class="cursor-pointer" viewBox="0 0 20 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg" (click)="(this.openModal = true); handleClick(visitor.id||0)">
                    <path
                      d="M11 14H10V10H9M10 6H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                      stroke="#1C64F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </td>
              </tr>
            }
          } @else {
            <tr>
              <td colspan="9" class="px-6 py-10">
                <div class="flex flex-col items-center justify-center text-center">
                  <p class="text-xl font-semibold text-gray-700 mb-2">No visitors found</p>
                  <p class="text-gray-500">We couldn't find any {{ searchParams.status || '' }} visitors that match your
                    search criteria.</p>
                  <button (click)="clearSearch()"
                          class="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out">
                    Clear Search
                  </button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                    (pageChanged)="onPageChange($event)"></app-pagination>
  </div>
</div>


@if (openModal) {
  <div id="crud-modal" tabindex="-1" aria-hidden="true"
       class=" fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50">
    <div class="relative p-4 w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg">
      <div class="relative">
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <h3 class="text-lg font-semibold text-gray-900 flex items-center">
            <span>{{ visitorDTO[0].title }} {{ visitorDTO[0].name }}</span>
            <span class="status-badge ml-4" [ngClass]="visitorDTO[0].status.toLowerCase()">
    {{ visitorDTO[0].status }}
  </span>
          </h3>

          <button type="button" (click)="closeModal()"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                  data-modal-toggle="crud-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class=" space-y-6">

          <app-visitor-create [hideInGrid]="false" [isFormDisabled]="true"
                              [gridCols]="'xl:grid-cols-3'"></app-visitor-create>
          <div class="flex items-center justify-between p-2 border-b rounded-t"></div>

          @if (visitorDTO) {
            <div class="flex gap-4 pb-4 float-right">
              @switch (visitorDTO[0].status.toLowerCase()) {
                @case ('pending') {
                  @if (!cancelling) {
                    @if (permissionFlags.flags.canDeleteVisitor()) {
                      <button (click)="delete()" type="button" class="button-blue">Delete</button>
                    }
                    @if (permissionFlags.flags.canCreateVisitor()) {
                      <button routerLink="/visitor-create" type="button" class="button-blue">Edit Details</button>
                    }
                    @if (permissionFlags.flags.canRejectVisitor()) {
                      <button (click)="cancelling = true" type="button" class="button-red">Reject</button>
                    }
                  }
                  @if (cancelling) {
                    <div class="relative">
                      <input [(ngModel)]="reason" type="text" id="rejectReason"
                             class="selectField"
                             placeholder=" "/>
                      <label for="rejectReason" class="label">Reject Reason</label>
                    </div>
                    <button type="button" (click)="reject()"
                            class="button-red">
                      Confirm Reject
                    </button>
                    <button type="button" (click)="onDiscard()"
                            class="button-gray">
                      Discard
                    </button>
                  }
                  @if (!cancelling) {
                    @if (permissionFlags.flags.canApproveVisitor()) {
                      <button (click)="approve()" type="button" class="button-blue">Approve</button>
                    }
                  }
                }
                @case ('approved') {
                  @if (!cancelling) {
                    @if (permissionFlags.flags.canCancelVisitor()) {
                      <button type="button" (click)="cancelling = true" class="button-orange">Cancel Visit</button>
                    }
                    @if (permissionFlags.flags.canCheckInVisitor()) {
                      <button type="button" (click)="checkIn()"
                              class="button-blue">
                        Confirm Checkin
                      </button>
                    }
                  }
                  @if (cancelling) {
                    <div class="relative">
                      <input [(ngModel)]="reason" type="text" id="cancelReason"
                             class="selectField"
                             placeholder=" "/>
                      <label for="cancelReason" class="label">Cancel Reason</label>
                    </div>
                    <button type="button" (click)="cancel()"
                            class="button-orange">
                      Confirm Cancel
                    </button>
                    <button type="button" (click)="onDiscard()"
                            class="button-gray">
                      Discard
                    </button>
                  }
                }
                @case ('checkedin') {
                  @if (permissionFlags.flags.canCheckOutVisitor()) {
                    <button type="button" (click)="checkOut()" class="button-blue">Confirm Checkout</button>
                  }
                }
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}

