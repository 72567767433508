import { Routes } from '@angular/router';
import { VisitorCreateComponent, VisitorGridComponent } from "./modules";
import {AuthGuard} from "./core";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'visitors',
    pathMatch: 'full'
  },
  {
    path: 'visitors',
    component: VisitorGridComponent,

  },
  {
    path: 'visitor-create',
    component: VisitorCreateComponent,
    // canActivate: [AuthGuard]
  },
];
